import React from "react"

import Layout from "../Layout"
import SEO from "../SEO"
import { ContentBlock } from "../PageBlocks/ContentBlock"
import { Soundcloud } from "../PageBlocks/Soundcloud"

export const Page = ({ pageContext: { isHome }, data: { contentfulPage: data } }) => (
  <Layout className="item">
    <SEO isHome={isHome} pageTitle={data.title} />
    {data.layoutBlocks.map(block => {
      switch (block.__typename) {
        case "ContentfulPageContentBlock":
          return <ContentBlock {...block} key={block.id} />
        case "ContentfulPageSoundcloud":
          return <Soundcloud {...block} key={block.id} />
        default:
          return null
      }
    })}
  </Layout>
)
