import React from "react"

import "./style.css"

export const Soundcloud = ({ heading, showHeading, url }) => (
  <>
    <iframe
      title={heading}
      width="100%"
      height="500"
      scrolling="no"
      frameborder="no"
      src={url}
    />
    {showHeading && (
      <div className="block-title">
        <h2>{heading}</h2>
      </div>
    )}
  </>
)
