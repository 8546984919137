import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import "./style.css"

const Thumbnail = ({ src, alt }) => <img src={src} alt={alt} />

export const ContentBlock = ({
  thumbnail,
  body,
  heading,
  fullWidthThumbnail,
  showHeading,
  headingAlignment,
}) => {
  const align = headingAlignment ? headingAlignment.toLowerCase().replace(/[^a-z]/g, "") : '';
  const isBottomHeading = align.startsWith('bottom');
  const isCenteredHeading = align.endsWith('centered');

  return (
    <div
      className={`content-block ${fullWidthThumbnail ? "fullWidth" : ""} ${heading
        .toLowerCase()
        .replace(/\s/g, "-")
        .replace(/[^a-z-]/g, "")}`}
    >
      {thumbnail && fullWidthThumbnail && (
        <Thumbnail src={thumbnail.file.url} alt={thumbnail.title} />
      )}
      {showHeading && !isBottomHeading && (
        <div className={`block-title ${isCenteredHeading ? "centered" : ""}`}>
          <h2>{heading}</h2>
        </div>
      )}
      <div className="block-container">
        {thumbnail && !fullWidthThumbnail && (
          <Thumbnail src={thumbnail.file.url} alt={thumbnail.title} />
        )}
        <div className={`block-text ${!thumbnail ? "columns" : ""}`}>
          {documentToReactComponents(body.json)}
        </div>
      </div>
      {showHeading && isBottomHeading && (
        <div className={`block-title ${isCenteredHeading ? "centered" : ""}`}>
          <h2>{heading}</h2>
        </div>
      )}
    </div>
  )
}
