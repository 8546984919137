import { graphql } from 'gatsby'
import { Page } from './Page';

export default Page;

export const pageQuery = graphql`
  query PageBySlug($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      layoutBlocks {
        ... on ContentfulPageSoundcloud {
          id
          heading
          showHeading
          url
        }
        ... on ContentfulPageContentBlock {
          id
          heading
          showHeading
          headingAlignment
          fullWidthThumbnail
          thumbnail {
            id
            title
            file {
              url
            }
          }
          body {
            json
          }
        }
      }
    }
  }
`
